import { InputAdornment, TextField, Typography } from "@material-ui/core";
import { useStyles } from "../../styles/styles";
import { useStarmapData } from "../../redux/slice";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import { PLACES_API_KEY } from "../../data/constants";
import { reactSelectStyles } from "../overrides";
import moment from "moment";

export const Tab3 = () => {
  const classes = useStyles();

  const {
    d_setLat,
    d_setLng,
    d_setTitle,
    lat,
    lng,
    date,
    primaryText,
    d_setSubTitle,
    d_setPrimaryText,
    d_setDate,
    rawDate,
  } = useStarmapData();
  const handleLatChange = (val) => {
    if (val) d_setLat(val);
  };
  const handleLngChange = (val) => {
    if (val) d_setLng(val);
  };
  const onLocationChange = async (place) => {
    const res = await geocodeByPlaceId(place.value.place_id);
    const latlng = await getLatLng(res[0]);
    const address = [];
    const timeString = `${moment(date)
      .format("Do MMM YYYY")
      .toUpperCase()} AT ${moment(date).format("h:mm A")}`;
    address.push(timeString);
    address.push(res[0].address_components[0].long_name);
    let subtitle = "";
    if (res[0].address_components.length >= 4) {
      subtitle =
        res[0].address_components[2].long_name +
        ", " +
        res[0].address_components[3].long_name;
    } else if (res[0].address_components.length === 3) {
      subtitle =
        res[0].address_components[1].long_name +
        ", " +
        res[0].address_components[2].long_name;
    }
    address.push(subtitle);
    // d_setSubTitle(subtitle);
    d_setPrimaryText(address);
    d_setLng(latlng.lng);
    d_setLat(latlng.lat);
  };

  const parseDate = (d) => {
    if (d === null) {
      return;
    }

    const timeString = `${moment(d)
      .format("Do MMM YYYY")
      .toUpperCase()} AT ${moment(d).format("h:mm A")}`;
    if (primaryText[0]) {
      const temp = [...primaryText];
      temp[0] = timeString;
      d_setPrimaryText(temp);
    } else {
      const temp = [...primaryText];
      temp.push(timeString);
      d_setPrimaryText(temp);
    }
    d_setDate(d);
  };
  return (
    <>
      <div className={classes.inputWrapper}>
        <Typography variant="subtitle2">Location</Typography>
        {/* <TextField variant="outlined" fullWidth /> */}
        <GooglePlacesAutocomplete
          apiKey={PLACES_API_KEY}
          selectProps={{
            // selectedLocation,
            onChange: onLocationChange,
            styles: reactSelectStyles,
          }}
        />
      </div>
      <div className={classes.inputWrapper}>
        <Typography variant="subtitle2">Date and time</Typography>
        <TextField
          variant="outlined"
          fullWidth
          value={rawDate}
          type="datetime-local"
          onChange={(e) => parseDate(e.target.value)}
        />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 10,
        }}
      >
        <div style={{ flex: 1, marginRight: 10 }}>
          <Typography variant="subtitle2">Latitude</Typography>
          <div className={classes.latlngInputWrapper}>
            <TextField
              variant="outlined"
              type="number"
              value={lat}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {lat > 0 ? "°N" : "°S"}
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleLatChange(parseFloat(e.target.value))}
            />
          </div>
        </div>
        <div style={{ flex: 1, marginLeft: 10 }}>
          <Typography variant="subtitle2">Longitude</Typography>
          <div className={classes.latlngInputWrapper}>
            <TextField
              type="number"
              value={lng}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {lng > 0 ? "°E" : "°W"}
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleLngChange(parseFloat(e.target.value))}
              variant="outlined"
            />
          </div>
        </div>
      </div>
    </>
  );
};
