import * as d3 from "d3";
import moment from "moment-timezone";
import { map_styles, sizes } from "../../data/mapData";

const MOON_RADIUS = 5;
const sizeToStyleMap = [
  {
    mapWidthMultiplier: 0.4,
    mapPos: (width, height) => [width * 0.5, height * 0.39],
    textPos: (width, height) => [width * 0.5, height * 0.82],
  },
];
export default function drawStarMap(
  {
    canvas,
    res_multiplier,
    date,
    lat,
    lng,
    layout,
    starData,
    dustCloudData,
    constData,
    planetData,
    showMilkyway,
    showGraticule,
    showConstellation,
    showMoon,
    rot,
    primaryText,
    title,
    size,
    subtitle,
    colorVariation,
    image,
    moonData,
  },
  canvasGenerator
) {
  const { primaryCol, secondaryCol } =
    map_styles[0].colorVariations[colorVariation];
  title = title || "";
  subtitle = subtitle || "";
  const width = sizes[0].width;
  const height = sizes[0].height;
  const ctx = canvas.getContext("2d");
  const target_res = {
    width: width * res_multiplier,
    height: height * res_multiplier,
  };
  ctx.canvas.width = target_res.width;
  ctx.canvas.height = target_res.height;

  ctx.fillStyle = "#fff";
  ctx.fillRect(0, 0, target_res.width, target_res.height);
  ctx.beginPath();
  ctx.drawImage(image[0], 0, 0, target_res.width, target_res.height);

  const { mapWidthMultiplier } = sizeToStyleMap[0];
  let mapWidth = mapWidthMultiplier * target_res.width;
  const mapPosition = sizeToStyleMap[0].mapPos(
    target_res.width,
    target_res.height
  );
  const projection = d3
    .geoStereographic()
    .scale(mapWidth)
    .translate(mapPosition)
    .clipAngle(90);
  projection.reflectX(true);
  projection.rotate(rot);
  const path = d3.geoPath().projection(projection).context(ctx);
  //milyway
  if (showMilkyway) {
    ctx.beginPath();
    ctx.fillStyle = "#fff";
    ctx.globalAlpha = 0.2;
    path(dustCloudData);
    ctx.fill();
    ctx.closePath();
    ctx.globalAlpha = 1;
  }
  //stars
  ctx.fillStyle = "#fff";
  ctx.lineWidth = 0.5;
  let magExtent = d3.extent(starData.features, function (d) {
    return d.properties.mag;
  });
  let rScale = d3
    .scaleSqrt()
    .domain(magExtent)
    .range([1.5 * res_multiplier, 0.1 * res_multiplier]);
  starData.features.forEach((d) => {
    ctx.beginPath();
    path.pointRadius(rScale(d.properties.mag));
    path({ type: "Point", coordinates: d.geometry.coordinates });
    ctx.fill();
    ctx.closePath();
  });

  //constilalttions
  if (showConstellation) {
    ctx.beginPath();
    ctx.lineWidth = 0.3 * res_multiplier;
    path(constData);
    ctx.strokeStyle = "#fff";
    ctx.stroke();
    ctx.closePath();
  }
  //graticule
  if (showGraticule) {
    ctx.beginPath();
    const graticule = d3.geoGraticule();
    graticule.extent([
      [-180, -180],
      [180, 180],
    ]);
    graticule.stepMinor([15, 10]);
    ctx.lineWidth = 0.05 * res_multiplier;
    path(graticule());
    ctx.strokeStyle = "#fff";
    ctx.stroke();
    ctx.closePath();
  }

  if (showMoon) {
    ctx.save();
    let pt = projection(moonData.moonPos);
    let s = MOON_RADIUS * res_multiplier,
      r = s / 2,
      ag = moonData.age,
      ph = 0.5 * (1 - Math.cos(ag)),
      e = 1.6 * Math.abs(ph - 0.5) + 0.01,
      dir = ag > Math.PI,
      termdir = Math.abs(ph) > 0.5 ? dir : !dir;
    ctx.beginPath();
    ctx.arc(
      mapPosition[0],
      mapPosition[1],
      mapWidthMultiplier * target_res.width,
      0,
      2 * Math.PI
    );
    ctx.clip();
    // ctx.moveTo(pt[0], pt[1]);
    ctx.beginPath();
    // ctx.moveTo(pt[0], pt[1]);
    ctx.strokeStyle = "#fff";
    ctx.lineWidth = 0.5 * res_multiplier;
    ctx.arc(pt[0], pt[1], r, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.stroke();
    ctx.fillStyle = "#fff";
    ctx.beginPath();
    ctx.moveTo(pt[0], pt[1]);
    ctx.arc(pt[0], pt[1], r, -Math.PI / 2, Math.PI / 2, dir);
    ctx.scale(e, 1);
    ctx.arc(pt[0] / e, pt[1], r, Math.PI / 2, -Math.PI / 2, termdir);
    ctx.closePath();
    ctx.fill();
    ctx.restore();
  }
  const textPosition = sizeToStyleMap[0].textPos(
    target_res.width,
    target_res.height
  );
  textPosition[1] -= primaryText.length * 4 * res_multiplier;
  ctx.textAlign = "center";

  //title
  calculateShrikage(
    ctx,
    title,
    "avenir",
    6.5,
    res_multiplier,
    target_res.width
  );
  ctx.fillStyle = secondaryCol;
  ctx.fillText(title, textPosition[0], textPosition[1]);

  //subtitle
  ctx.textBaseline = "middle";
  textPosition[1] += res_multiplier * 10;
  calculateShrikage(
    ctx,
    subtitle,
    "sacramento",
    12,
    res_multiplier,
    target_res.width
  );
  ctx.fillStyle = secondaryCol;
  ctx.fillText(subtitle, textPosition[0], textPosition[1]);

  // //date text
  // textPosition[1] += 8 * res_multiplier;
  // let dateText = `${moment(date).format("Do MMMM YYYY").toUpperCase()}`;
  // ctx.font = 5 * res_multiplier + "px QuattrocentoSans-Regular";
  // ctx.fillText(dateText, textPosition[0], textPosition[1]);
  // textPosition[1] += 1.5 * res_multiplier;
  //location text

  //primary text

  textPosition[1] += res_multiplier * 5;
  ctx.textBaseline = "middle";
  for (let text of primaryText) {
    textPosition[1] += res_multiplier * 7;
    calculateShrikage(
      ctx,
      text.toUpperCase(),
      "avenir",
      5,
      res_multiplier,
      target_res.width
    );
    ctx.fillText(text.toUpperCase(), textPosition[0], textPosition[1]);
  }
}

const calculateShrikage = (
  ctx,
  text,
  font,
  initalsize,
  res_multiplier,
  canvasWidth
) => {
  const viewPortWidth = 0.7 * canvasWidth;
  let init = initalsize;
  let output = init * res_multiplier + "px " + font;
  ctx.font = output;
  let textsize = ctx.measureText(text).width;
  while (textsize > viewPortWidth) {
    init -= 0.1;
    ctx.font = init * res_multiplier + "px " + font;
    textsize = ctx.measureText(text).width;
  }
};
