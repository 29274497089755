import React from "react";
import { useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../styles/styles";
import ArrowDropDownIcon from "@material-ui/icons/ExpandMore";
import { Tab1 } from "./tabs/tab1";
import { Tab2 } from "./tabs/tab2";
import { Tab3 } from "./tabs/tab3";
import { Tab4 } from "./tabs/tab4";
import { Tab5 } from "./tabs/tab5";

export default function WideConsole({ planetData }) {
  const classes = useStyles();
  const [collapsedListIndex, setCollapedListIndex] = useState(0);

  const handleChange = (index) => (event, isExpanded) => {
    if (collapsedListIndex === index) {
      setCollapedListIndex(9);
    } else {
      setCollapedListIndex(index);
    }
  };

  // useEffect(() => {
  //   let wp_item = {};
  //   for (const item of wpData) {
  //     if (
  //       item.attributes["attribute_frame-size"] === sizes[size].label &&
  //       item.attributes["attribute_type"] ===
  //         (format === 0 || format === 1
  //           ? formats[format].type
  //           : `${formats[format].type}(${frame_styles[frame].label})`)
  //     ) {
  //       wp_item.price = item.display_price;
  //       wp_item.id = item.variation_id;
  //     }
  //     setWpItem(wp_item);
  //   }
  // }, [format, frame, size, wpData]);

  // const checkOut = async (redirect) => {
  //   setIsDoingCheckout(true);
  //   const data = {
  //     lat,
  //     lng,
  //     primaryText,
  //     date: date.getTime(),
  //     title,
  //     size,
  //     format,
  //     variation,
  //     frame,
  //     subtitle,
  //     style,
  //     inverse,
  //     colorVariation,
  //     showPrimaryText,
  //     showTitle,
  //     showSubtitle,
  //     rot: calculateRot(date, lat, lng),
  //   };
  //   try {
  //     await handleCheckout(data, wpItem.id, redirect, TESTING);
  //     setIsDoingCheckout(false);
  //   } catch (e) {
  //     console.log(e);
  //     alert("Some error encountered. Please try again later.");
  //     setIsDoingCheckout(false);
  //   }
  // };

  return (
    <div className={classes.sideBarWrapper}>
      <div className={classes.staticTopInfo}>
        <Typography variant="subtitle1" style={{ color: "white" }}>
          Design your own starmap
        </Typography>
        <Typography style={{ color: "white" }}>
          Change location, dates, appearance and size.
        </Typography>
      </div>
      <Accordion expanded={collapsedListIndex === 0} onChange={handleChange(0)}>
        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
          <Typography variant="subtitle1">Map Style</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Tab1 />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={collapsedListIndex === 1} onChange={handleChange(1)}>
        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
          <Typography variant="subtitle1">Map Color</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Tab2 />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={collapsedListIndex === 2} onChange={handleChange(2)}>
        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
          <Typography variant="subtitle1">Location and Date</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Tab3 />
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={collapsedListIndex === 3} onChange={handleChange(3)}>
        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
          <Typography variant="subtitle1">Custom Text</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Tab4 />
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={collapsedListIndex === 4} onChange={handleChange(4)}>
        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
          <Typography variant="subtitle1">Size & Checkout</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Tab5 planetData={planetData} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
