import { FormControlLabel, Switch, Typography } from "@material-ui/core";
import { map_styles } from "../../data/mapData";
import { useStyles } from "../../styles/styles";
import { useStarmapData } from "../../redux/slice";

export const Tab1 = () => {
  const classes = useStyles();

  const {
    style,
    showConstellation,
    showGraticule,
    showMilkyway,
    d_setShowMilkyway,
    showMoon,
    d_setShowMoon,
    d_setShowGraticule,
    d_setShowConstellation,
    d_setStyle,
  } = useStarmapData();
  return (
    <>
      <div className={classes.stylesWrapper}>
        {map_styles.map((tile, index) => (
          <div
            className={classes.styleItem}
            key={`${tile.label}-stylevar`}
            onClick={() => d_setStyle(index)}
          >
            <div className={classes.styleImageWrap}>
              <img
                src={tile.src}
                alt={tile.label}
                className={classes.styleImage}
                style={
                  index === style
                    ? { borderColor: "rgba(47,57,75,.8)" }
                    : { borderColor: "rgba(47,57,75,.2)" }
                }
              />
              <Typography className={classes.styleText}>
                {tile.label}
              </Typography>
            </div>
          </div>
        ))}
      </div>
      <div>
        <div
          aria-label="position"
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: 20,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={showConstellation}
                onChange={() => d_setShowConstellation(!showConstellation)}
              />
            }
            label="Constellations"
            labelPlacement="start"
            className={classes.switch}
          />
          <FormControlLabel
            className={classes.switch}
            value="start"
            control={
              <Switch
                color="primary"
                checked={showGraticule}
                onChange={() => d_setShowGraticule(!showGraticule)}
              />
            }
            label="Enable Grid"
            labelPlacement="start"
          />
          <FormControlLabel
            className={classes.switch}
            value="start"
            control={
              <Switch
                color="primary"
                onChange={() => d_setShowMoon(!showMoon)}
                checked={showMoon}
              />
            }
            label="Moon"
            labelPlacement="start"
          />
          <FormControlLabel
            className={classes.switch}
            value="start"
            control={
              <Switch
                color="primary"
                checked={showMilkyway}
                onChange={() => d_setShowMilkyway(!showMilkyway)}
              />
            }
            label="Dust Cloud"
            labelPlacement="start"
          />
        </div>
      </div>
    </>
  );
};
