import { createSlice } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import { fetchWordpressData } from "./thunk";

export const mainSlice = createSlice({
  name: "main",
  initialState: {
    primaryCol: "#000",
    secondaryCol: "#fff",
    lat: 40,
    lng: 90,
    size: 0,
    style: 0,
    frame: 0,
    format: 0,
    date: "2021-10-12T00:00",
    showMoon: true,
    showConstellation: true,
    showGrid: true,
    showMilkyway: false,
    primaryText: ["4th June 2021", "06:24", "7lb 6oz", "LONDON"],
    subtitle: "Ella Elizabeth Roy",
    title: "THE STARS ON THE DAY YOU WERE BORN",
    inverse: true,
    colorVariation: 0,
    wpData: [],
  },
  reducers: {
    setPrimaryColor: (state, action) => {
      state.primaryCol = action.payload;
    },
    setSecondaryColor: (state, action) => {
      state.secondaryCol = action.payload;
    },

    setLatitude: (state, action) => {
      state.lat = action.payload;
    },

    setLongitude: (state, action) => {
      state.lng = action.payload;
    },
    setShowConstellation: (state, action) => {
      state.showConstellation = action.payload;
    },
    setShowMilkyway: (state, action) => {
      state.showMilkyway = action.payload;
    },
    setShowMoon: (state, action) => {
      state.showMoon = action.payload;
    },
    setShowGrid: (state, action) => {
      state.showGrid = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },

    setTitle: (state, action) => {
      state.title = action.payload;
    },

    setSubTitle: (state, action) => {
      state.subtitle = action.payload;
    },

    setPrimaryText: (state, action) => {
      state.primaryText = action.payload;
    },
    setSize: (state, action) => {
      state.size = action.payload;
    },
    setFormat: (state, action) => {
      state.format = action.payload;
    },
    setStyle: (state, action) => {
      state.style = action.payload;
    },
    setFrame: (state, action) => {
      state.frame = action.payload;
    },
    setInverse: (state, action) => {
      state.inverse = action.payload;
    },
    setColorVariation: (state, action) => {
      state.colorVariation = action.payload;
    },
  },
  extraReducers: {
    [fetchWordpressData.fulfilled]: (state, action) => {
      state.wpData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions;
const {
  setPrimaryColor,
  setSecondaryColor,
  setLatitude,
  setLongitude,
  setShowConstellation,
  setShowMilkyway,
  setShowMoon,
  setShowGrid,
  setDate,
  setPrimaryText,
  setTitle,
  setSubTitle,
  setFormat,
  setSize,
  setStyle,
  setFrame,
  setInverse,
  setColorVariation,
} = mainSlice.actions;

export default mainSlice.reducer;
export function useStarmapData() {
  const dispatch = useDispatch();
  const primaryCol = useSelector((state) => state.main.primaryCol);
  const secondaryCol = useSelector((state) => state.main.secondaryCol);
  const primaryText = useSelector((state) => state.main.primaryText);
  const lat = useSelector((state) => state.main.lat);
  const lng = useSelector((state) => state.main.lng);
  const date = useSelector((state) => new Date(state.main.date));
  const rawDate = useSelector((state) => state.main.date);
  const showConstellation = useSelector(
    (state) => state.main.showConstellation
  );
  const showGraticule = useSelector((state) => state.main.showGrid);
  const showMoon = useSelector((state) => state.main.showMoon);
  const showMilkyway = useSelector((state) => state.main.showMilkyway);
  const size = useSelector((state) => state.main.size);
  const format = useSelector((state) => state.main.format);
  const title = useSelector((state) => state.main.title);
  const subtitle = useSelector((state) => state.main.subtitle);
  const style = useSelector((state) => state.main.style);
  const frame = useSelector((state) => state.main.frame);
  const wpData = useSelector((state) => state.main.wpData);
  const inverse = useSelector((state) => state.main.inverse);
  const showPrimaryText = useSelector((state) => state.main.showPrimaryText);
  const showTitle = useSelector((state) => state.main.showTitle);
  const showSubtitle = useSelector((state) => state.main.showSubtitle);
  const colorVariation = useSelector((state) => state.main.colorVariation);
  const d_setPrimaryCol = (col) => dispatch(setPrimaryColor(col));
  const d_setSecondaryCol = (col) => dispatch(setSecondaryColor(col));
  const d_setLat = (lat) => dispatch(setLatitude(lat));
  const d_setLng = (lng) => dispatch(setLongitude(lng));
  const d_setShowConstellation = (bool) => dispatch(setShowConstellation(bool));
  const d_setShowMoon = (bool) => dispatch(setShowMoon(bool));
  const d_setShowMilkyway = (bool) => dispatch(setShowMilkyway(bool));
  const d_setDate = (date) => dispatch(setDate(date));
  const d_setPrimaryText = (text) => dispatch(setPrimaryText(text));
  const d_setFormat = (index) => dispatch(setFormat(index));
  const d_setSize = (index) => dispatch(setSize(index));
  const d_setTitle = (text) => dispatch(setTitle(text));
  const d_setSubTitle = (text) => dispatch(setSubTitle(text));
  const d_setStyle = (index) => dispatch(setStyle(index));
  const d_setFrame = (index) => dispatch(setFrame(index));
  const d_fetchWpData = () => dispatch(fetchWordpressData());
  const d_setShowGraticule = (bool) => dispatch(setShowGrid(bool));
  const d_setInverse = (bool) => dispatch(setInverse(bool));
  const d_setColorVariation = (index) => dispatch(setColorVariation(index));
  return {
    primaryCol,
    secondaryCol,
    primaryText,
    lat,
    lng,
    date,
    rawDate,
    title,
    subtitle,
    showConstellation,
    showGraticule,
    showMoon,
    showMilkyway,
    size,
    format,
    style,
    frame,
    wpData,
    inverse,
    colorVariation,
    showTitle,
    showPrimaryText,
    showSubtitle,
    d_setPrimaryCol,
    d_setSecondaryCol,
    d_setLat,
    d_setLng,
    d_setShowConstellation,
    d_setShowMilkyway,
    d_setShowMoon,
    d_setColorVariation,
    d_setDate,
    d_setPrimaryText,
    d_setTitle,
    d_setSubTitle,
    d_setFormat,
    d_setSize,
    d_setStyle,
    d_setFrame,
    d_fetchWpData,
    d_setInverse,
    d_setShowGraticule,
  };
}
