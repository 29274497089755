import { createMuiTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import { grey, red } from "@material-ui/core/colors";
import { BorderStyle } from "@material-ui/icons";

export const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 5,
      },
      label: {
        textTransform: "none",
        fontWeight: 500,
      },
      contained: {
        color: "white",
      },
      containedPrimary: {
        color: "white",
        backgroundColor: "#bd998d",
        "&:hover": {
          backgroundColor: "green",
        },
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#969696",
      },
    },
    MuiAccordion: {
      root: {
        border: "none",
        "&$expanded": {
          margin: 0,
        },
        "&::before": {
          display: "none",
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
    },
    MuiTab: {
      root: {
        backgroundColor: "#3A3A3A",
        textTransform: "none",
      },
      textColorPrimary: {
        color: "white",
        fontWeight: 600,
        "&$selected": {
          color: "#969696",
        },
      },
    },
    MuiListSubheader: {},
    MuiTabs: {},
    MuiInputBase: {
      fullWidth: {},
      input: {
        backgroundColor: "#fff",
      },
    },

    MuiAccordionSummary: {
      root: {
        backgroundColor: "#fff",
        transition: "none",
        height: 50,
        minHeight: 50,
        flexDirection: "row-reverse",
        borderBottom: "1px solid #eee",
        "&$expanded": {
          minHeight: 50,
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        borderBottom: "1px solid #eee",
        backgroundColor: "#f9fafb",
        display: "block",
      },
    },
  },
  palette: {
    primary: {
      main: '#180A0A',
    },
    secondary: {
      main: "#597387",
    },
  },
  typography: {
    fontFamily: "Karla",
    subtitle1: {
      color: "#2f394BCC",
      fontWeight: 700,
    },
    body1: {
      color: "grey",
      fontSize: 15,
    },
    subtitle2: {
      fontSize: 15,
      color: "grey",
      marginBottom: 10,
    },
  },
});
