import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles({
  root: {
    // flexWrap: "wrap-reverse",
    height: "100%",
  },
  logo: {
    width: "8em",
    marginBottom: -15,
  },
  hidden: {
    display: "none",
  },
  heading: {
    fontSize: 20,
    fontWeight: 600,
    textAlign: "center",
  },
  buttonSelectedLabel: {
    color: "white",
  },

  buttonNotSelectedLabel: {
    color: "#2E3B49",
    fontWeight: 600,
  },
  thumbnail: {
    width: "100%",
    height: "auto",
  },
  staticTopInfo: {
    backgroundColor: "#151515",
    padding: 20,
  },
  staticInfo: {},
  sideBarWrapper: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    boxShadow: "-2px -2px 4px 0 rgb(35 69 152 / 10%)",
    height: "100%",
  },
  buttonGroupWrapper: {
    marginTop: 5,
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  grid: {
    // padding: 20,
  },
  sizesPaper: {
    padding: 40,
    borderWidth: 3,
    borderColor: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  tabContainer: {
    backgroundColor: "#fff",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    marginTop: 20,
  },
  inputWrapper: {
    marginTop: 10,
  },
  inputLabel: {
    marginBottom: 5,
  },
  titleText: {
    fontWeight: 400,
  },
  title: {
    marginBottom: 20,
  },
  titleSubText: {
    color: "white",
  },
  secondaryText: {
    fontSize: 18,
  },
  copyright: {
    marginTop: 50,
  },
  paper: {
    backgroundColor: "white",
    padding: "1em",
    marginTop: 10,
  },
  textField: {
    width: "10em",
  },
  descriptionText: {
    marginTop: 20,
  },
  checkoutTextContainer: {
    marginTop: 10,
    padding: 10,
  },
  checkoutTextContainer2: {
    borderStyle: "dashed",
    borderColor: "white",
    borderWidth: 1,
    marginTop: 10,
    padding: 10,
  },
  checkoutText: {
    color: "white",
  },
  floatingLineCounter: {
    position: "absolute",
    bottom: 2,
    right: 6,
    color: "grey",
  },
  button: {
    height: 40,
    width: "45%",
    fontSize: 15,
  },
  thumbOverlay: {
    backgroundColor: "black",
    opacity: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    width: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    transition: "0.5s ease",
    justifyContent: "center",
    "&:hover": {
      opacity: 0.8,
    },
  },
  gridIcon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  stylesWrapper: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
  },
  styleItem: {
    width: "25%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  styleText: {
    textAlign: "center",
  },
  styleImage: {
    height: 60,
    width: 60,
    objectPosition: "center",
    objectFit: "cover",
    borderRadius: 50,
    borderWidth: 4,
    borderStyle: "solid",
    overflow: "hidden",
  },
  styleImageWrap: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: 5,
  },
  switch: {
    flexBasis: "45%",
  },
  thumbOverlayVaraint: {
    backgroundColor: "black",
    opacity: 0.2,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    width: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    transition: "0.5s ease",
    justifyContent: "center",
    "&:hover": {
      opacity: 0.8,
    },
  },
  bgColors: {
    width: 100,
    height: 100,
    position: "relative",
    overflow: "hidden",
    margin: 2,
  },
  checkOutWrapper: {
    marginTop: 15,
    borderTopWidth: 2,
    borderTopColor: "#c3c3c3",
    borderTopStyle: "dotted",
    padding: 5,
    paddingTop: 15,
    display: "flex",
    flexDirection: "column",
  },
  latlngInputWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  divider: {
    marginTop: 20,
    marginBottom: 5,
  },
  innerSideBarWrapper: {
    backgroundColor: "#f2f2f2",
    padding: 20,
  },
  toggleWrapper: {
    backgroundColor: "rgba(58,58,58,0)",
    display: "flex",
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginTop: 20,
  },
  toggle: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    backgroundColor: "lightgrey",
    borderRadius: 10,
    margin: 5,
    padding: 10,
    flex: 1,
    transition: "0.5s ease",
    opacity: 0.3,
    "&:hover": {
      opacity: 1,
    },
  },
  toggleThumb: {
    borderRadius: 30,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#3a3a3a",
    width: 40,
    height: 40,
    marginRight: 20,
  },
  checkoutButtonWrapper: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
  },
});
