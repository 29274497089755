export const formats = [
  {
    type: "Digital",
    description:
      "We will send you a high resolution file to your email for you to print locally.",
  },
  {
    type: "Printed",
    description:
      "Printed on to museum grade art paper and using high grade inks. Your starmap is made to last generations.",
  },
  {
    type: "Framing Service",
    description:
      "We provide a framing service for your star map using our beautifully crafted wooden frames. Simply choose your colour and let us do the rest.",
  },
  {
    type: "Art Display",
    description:
      "The classic wooden art display with magnetic clasps easily secures your star map. Perfect for a modern touch to your home and ready to display in seconds.",
  },
];
export const sizes = [
  {
    label: "A5",
    size_label: "148x210mm",
    height: 218.4,
    width: 154.4,
    r_height: 8.27,
    r_width: 5.83,
  },
  {
    label: "A4",
    size_label: "210x297mm",
    height: 218.4,
    width: 154.4,
    r_height: 11.7,
    r_width: 8.3,
  },
  {
    label: "A3",
    size_label: "297x420mm",
    height: 218.4,
    width: 154.4,
    r_height: 16.5,
    r_width: 11.7,
  },
];
export const frame_styles = [
  {
    label: "Black",
    src: `${process.env.PUBLIC_URL}/images/frames/1.png`,
  },
  {
    label: "White",
    src: `${process.env.PUBLIC_URL}/images/frames/2.png`,
  },
  {
    label: "French Oak",
    src: `${process.env.PUBLIC_URL}/images/frames/3.png`,
  },
];

export const map_styles = [
  {
    label: "Classic",
    src: `${process.env.PUBLIC_URL}/images/classic/thumb.png`,
    variant: [],
    colorVariations: [
      {
        label: "Black",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/classic/black.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic/thumbnail/black.png`,
      },
      {
        label: "Rainbow",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/classic/colors.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic/thumbnail/colors.png`,
      },
      {
        label: "Dark Green",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/classic/dark_green.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic/thumbnail/dark_green.png`,
      },
      {
        label: "Deep Pink",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/classic/deep_pink.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic/thumbnail/deep_pink.png`,
      },
      {
        label: "Deep Blue",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/classic/deep_blue.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic/thumbnail/deep_blue.png`,
      },
      {
        label: "Dusty Pink",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/classic/dusty_pink.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic/thumbnail/dusty_pink.png`,
      },
      {
        label: "Grey",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/classic/grey.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic/thumbnail/grey.png`,
      },

      {
        label: "Light Blue",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/classic/light_blue.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic/thumbnail/light_blue.png`,
      },
      {
        label: "Light Green",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/classic/light_green.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic/thumbnail/light_green.png`,
      },
      {
        label: "Orange",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/classic/orange.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic/thumbnail/orange.png`,
      },
      {
        label: "Pink",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/classic/pink.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic/thumbnail/pink.png`,
      },
      {
        label: "Purple",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/classic/purple.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic/thumbnail/purple.png`,
      },
      {
        label: "Red",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/classic/red.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic/thumbnail/red.png`,
      },
    ],

    allowInverse: false,
  },

  {
    label: "Bordered",
    src: `${process.env.PUBLIC_URL}/images/classic_bordered/thumb.png`,
    variant: [],
    colorVariations: [
      {
        label: "Black",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/classic_bordered/black.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic_bordered/thumbnail/black.png`,
      },
      {
        label: "Rainbow",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/classic_bordered/colors.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic_bordered/thumbnail/colors.png`,
      },
      {
        label: "Dark Blue",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [
          `${process.env.PUBLIC_URL}/images/classic_bordered/dark_blue.png`,
        ],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic_bordered/thumbnail/dark_blue.png`,
      },
      {
        label: "Deep Purple",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [
          `${process.env.PUBLIC_URL}/images/classic_bordered/deep_purple.png`,
        ],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic_bordered/thumbnail/deep_purple.png`,
      },
      {
        label: "Grey",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/classic_bordered/grey.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic_bordered/thumbnail/grey.png`,
      },
      {
        label: "Light Blue",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [
          `${process.env.PUBLIC_URL}/images/classic_bordered/light_blue.png`,
        ],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic_bordered/thumbnail/light_blue.png`,
      },
      {
        label: "Neon Aqua",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [
          `${process.env.PUBLIC_URL}/images/classic_bordered/neon_aqua.png`,
        ],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic_bordered/thumbnail/neon_aqua.png`,
      },

      {
        label: "Neon Blue",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [
          `${process.env.PUBLIC_URL}/images/classic_bordered/neon_blue.png`,
        ],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic_bordered/thumbnail/neon_blue.png`,
      },
      {
        label: "Neon Lilac",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [
          `${process.env.PUBLIC_URL}/images/classic_bordered/neon_lilac.png`,
        ],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic_bordered/thumbnail/neon_lilac.png`,
      },
      {
        label: "Orange",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/classic_bordered/orange.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic_bordered/thumbnail/orange.png`,
      },
      {
        label: "Pink",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/classic_bordered/pink.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic_bordered/thumbnail/pink.png`,
      },
      {
        label: "Purple",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/classic_bordered/purple.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic_bordered/thumbnail/purple.png`,
      },
      {
        label: "Neon Rose",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [
          `${process.env.PUBLIC_URL}/images/classic_bordered/neon_rose.png`,
        ],
        thumbnail: `${process.env.PUBLIC_URL}/images/classic_bordered/thumbnail/neon_rose.png`,
      },
    ],

    allowInverse: false,
  },

  {
    label: "Watercolor",
    src: `${process.env.PUBLIC_URL}/images/watercolor/thumb.png`,
    variant: [],
    colorVariations: [
      {
        label: "Black",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/watercolor/black.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/watercolor/thumbnail/black.png`,
      },
      {
        label: "Aqua",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/watercolor/aqua.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/watercolor/thumbnail/aqua.png`,
      },

      {
        label: "Bright Blue",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/watercolor/bright_blue.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/watercolor/thumbnail/bright_blue.png`,
      },
      {
        label: "Burnt Orange",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/watercolor/burnt_orange.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/watercolor/thumbnail/burnt_orange.png`,
      },
      {
        label: "Lilac",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/watercolor/lilac.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/watercolor/thumbnail/lilac.png`,
      },
      {
        label: "Deep Pink",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/watercolor/deep_pink.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/watercolor/thumbnail/deep_pink.png`,
      },
      {
        label: "Deep Blue",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/watercolor/deep_blue.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/watercolor/thumbnail/deep_blue.png`,
      },

      {
        label: "Deep Purple",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/watercolor/deep_purple.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/watercolor/thumbnail/deep_purple.png`,
      },
      {
        label: "Green",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/watercolor/green.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/watercolor/thumbnail/green.png`,
      },
      {
        label: "Grey",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/watercolor/grey.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/watercolor/thumbnail/grey.png`,
      },
      {
        label: "Rainbow",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/watercolor/rainbow.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/watercolor/thumbnail/rainbow.png`,
      },
      {
        label: "Rose",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/watercolor/rose.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/watercolor/thumbnail/rose.png`,
      },
      {
        label: "Sky Blue",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/watercolor/sky_blue.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/watercolor/thumbnail/sky_blue.png`,
      },
    ],

    allowInverse: false,
  },
  {
    label: "Painted Sky",
    src: `${process.env.PUBLIC_URL}/images/painted_sky/thumb.png`,
    variant: [],
    colorVariations: [
      {
        label: "Black",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/painted_sky/black.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/painted_sky/thumbnail/black.png`,
      },
      {
        label: "Aqua",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/painted_sky/aqua.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/painted_sky/thumbnail/aqua.png`,
      },

      {
        label: "Blue",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/painted_sky/blue.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/painted_sky/thumbnail/blue.png`,
      },
      {
        label: "Rainbow",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/painted_sky/colors.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/painted_sky/thumbnail/colors.png`,
      },
      {
        label: "Deep Blue",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/painted_sky/deep_blue.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/painted_sky/thumbnail/deep_blue.png`,
      },
      {
        label: "Deep Green",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/painted_sky/deep_green.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/painted_sky/thumbnail/deep_green.png`,
      },
      {
        label: "Deep Lilac",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/painted_sky/deep_lilac.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/painted_sky/thumbnail/deep_lilac.png`,
      },

      {
        label: "Deep Purple",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/painted_sky/deep_purple.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/painted_sky/thumbnail/deep_purple.png`,
      },
      {
        label: "Grey",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/painted_sky/grey.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/painted_sky/thumbnail/grey.png`,
      },
      {
        label: "Lilac",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/painted_sky/lilac.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/painted_sky/thumbnail/lilac.png`,
      },
      {
        label: "Pink",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/painted_sky/pink.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/painted_sky/thumbnail/pink.png`,
      },
      {
        label: "Purple",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/painted_sky/purple.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/painted_sky/thumbnail/purple.png`,
      },
      {
        label: "Rose",
        primaryCol: "#fff",
        secondaryCol: "#000",
        src: [`${process.env.PUBLIC_URL}/images/painted_sky/rose.png`],
        thumbnail: `${process.env.PUBLIC_URL}/images/painted_sky/thumbnail/rose.png`,
      },
    ],

    allowInverse: false,
  },
];
