import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchWordpressData = createAsyncThunk("main/wpData", async () => {
  const wordpress_data = window.rpReactPlugin || {
    ajaxurl: "https://wildskyclub.com/wp-admin/admin-ajax.php",
  };
  const formData = new FormData();
  formData.append("action", "get_variations");
  // formData.append("nonce", wordpress_data.nonce);
  const req = await fetch(wordpress_data.ajaxurl, {
    method: "POST",
    body: formData,
    credentials: "same-origin",
  });
  const res = await req.json();
  return res;
});
