import {
  Button,
  CircularProgress,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from "@material-ui/core";
import { useStyles } from "../../styles/styles";
import { useStarmapData } from "../../redux/slice";
import { Add, CheckBoxOutlineBlank, Close } from "@material-ui/icons";
import CheckBoxOutlined from "@material-ui/icons/CheckBoxOutlined";
import { formats, frame_styles, sizes } from "../../data/mapData";
import React, { useEffect, useState } from "react";
import {
  calculateRot,
  calculateTimeOffset,
  euler,
  handleCheckout,
  transformDeg,
} from "../../helpers/util";

const TESTING = false;
const DEMO = true;

export const Tab5 = ({ planetData }) => {
  const classes = useStyles();

  const [wpItem, setWpItem] = useState({ price: undefined, id: undefined });
  const [isDoingCheckout, setIsDoingCheckout] = useState(false);
  const {
    d_setSize,
    size,
    d_setFormat,
    format,
    d_setFrame,
    frame,
    wpData,
    showGraticule,
    showConstellation,
    showMoon,
    showMilkyway,
    primaryText,
    title,
    subtitle,
    date,
    lat,
    lng,
    style,
    colorVariation,
  } = useStarmapData();

  const doCheckout = (redirect) => {
    setIsDoingCheckout(true);
    const ndate = calculateTimeOffset(date, lat, lng);
    const rot = calculateRot(ndate, lat, lng);
    let o = planetData.origin(ndate).spherical();
    let p = planetData.moon().equatorial(o);
    const moonPos = transformDeg(p.ephemeris.pos, euler["equatorial"]);
    const data = {
      showMilkyway,
      showGraticule,
      showConstellation,
      showMoon,
      rot,
      primaryText,
      title,
      subtitle,
      colorVariation,
      moonData: { moonPos, age: p.ephemeris.age },
      format,
      size,
      style,
    };
    try {
      handleCheckout(data, wpItem.id, redirect, TESTING);
    } catch (e) {
      alert("Oops. Some error occurred!");
      console.log(e);
    }
  };
  useEffect(() => {
    let wp_item = {};
    for (const item of wpData) {
      if (
        item.attributes["attribute_frame-size"] === sizes[size].label &&
        item.attributes["attribute_type"] ===
        (format === 0 || format === 1 || format === 3
          ? formats[format].type
          : `${formats[format].type}(${frame_styles[frame].label})`)
      ) {
        wp_item.price = item.display_price;
        wp_item.id = item.variation_id;
        wp_item.stock_status = item.is_in_stock;
      }
      setWpItem(wp_item);
    }
  }, [format, frame, size, wpData]);

  return (
    <>
      <div style={{ flexDirection: "column", display: "flex" }}>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            padding: 10,
            justifyContent: "space-evenly",
            marginTop: 10,
          }}
        >
          {sizes.map((item, index) => (
            <div
              onClick={() => d_setSize(index)}
              style={{
                margin: 5,
                borderWidth: 2,
                borderRadius: 5,
                borderColor: "rgb(118,118,118)",
                borderStyle: "solid",
                width: item.r_width * 12,
                height: item.r_height * 12,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                cursor: "pointer",
                flexDirection: "column",
                wordBreak: "break-all",
                backgroundColor:
                  index === size ? "rgba(0, 0, 0, 0.08)" : "inherit",
              }}
              key={`${index}-size`}
            >
              <Typography>{item.label}</Typography>
              <Typography style={{ fontSize: 10 }}>
                {item.size_label}
              </Typography>
            </div>
          ))}
        </div>

        <div className={classes.inputWrapper} style={{ flex: 4 }}>
          {formats.map((item, index) => (
            <ListItem
              key={index + "formats"}
              alignItems="flex-start"
              onClick={() => d_setFormat(index)}
              selected={format === index}
              style={{
                cursor: "pointer",
              }}
            >
              <ListItemText primary={item.type} secondary={item.description} />
            </ListItem>
          ))}
        </div>
      </div>
      {format === 2 && (
        <GridList cellHeight={120} className={classes.gridList}>
          <GridListTile key="Subheader" cols={2} style={{ height: "auto" }}>
            <ListSubheader component="div">Frame style</ListSubheader>
          </GridListTile>
          {frame_styles.map((tile, index) => (
            <GridListTile onClick={() => d_setFrame(index)} key={tile.label}>
              <img src={tile.src} alt={tile.label} />
              <div className={classes.thumbOverlay}></div>
              <GridListTileBar
                title={tile.label}
                actionIcon={
                  <IconButton className={classes.gridIcon}>
                    {index === frame ? (
                      <CheckBoxOutlined />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )}
                  </IconButton>
                }
              />
            </GridListTile>
          ))}
        </GridList>
      )}
      {(wpData.length !== 0 && !isDoingCheckout) || TESTING ? (
        <div className={classes.checkOutWrapper}>
          {!wpItem.stock_status && !TESTING && (
            <Typography
              style={{
                color: "red",
                fontStyle: "italic",
                fontSize: 14,
              }}
            >
              The selected product is currently out of stock.
            </Typography>
          )}
          <Typography
            variant="subtitle1"
            style={{ textAlign: "end", alignSelf: "flex-end" }}
          >
            Total : £ {wpItem.price}
          </Typography>
          <div className={classes.checkoutButtonWrapper}>
            <Button
              variant="outlined"
              style={{ marginRight: 10 }}
              fullWidth
              disabled={!wpItem.stock_status}
              onClick={() => doCheckout(false)}
            >
              Add to cart
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!wpItem.stock_status && !TESTING}
              fullWidth
              // style={{ backgroundColor: "#bd998d", color: "white" }}
              onClick={() => doCheckout(true)}
            >
              Checkout
            </Button>
          </div>
          <Typography
            style={{ fontStyle: "italic", marginTop: 15, fontSize: 14 }}
          >
            Disclaimer : Please check your design and that all spelling is
            correct as refunds or replacements for customer error cannot be
            made.
          </Typography>
        </div>
      ) : (
        !DEMO ? (
          <div className={classes.checkOutWrapper}>
            <CircularProgress color="secondary" style={{ alignSelf: "center" }} />
          </div>
        ) : (
          <div>
            <Typography style={{ fontWeight: 'bold', marginLeft: 20, marginRight: 20 }}>Disclaimer : This is only a demo of a full product. Please contact Satkar Dhakal to integrate this into your site.</Typography>
          </div>
        )
      )}
    </>
  );
};
