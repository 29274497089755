
import {deg2rad , halfπ} from './mathConstants';

export var Trig = {
    sinh: function (val) { return (Math.pow(Math.E, val)-Math.pow(Math.E, -val))/2; },
    cosh: function (val) { return (Math.pow(Math.E, val)+Math.pow(Math.E, -val))/2; },
    tanh: function (val) { return 2.0 / (1.0 + Math.exp(-2.0 * val)) - 1.0; },
    asinh: function (val) { return Math.log(val + Math.sqrt(val * val + 1)); },
    acosh: function (val) { return Math.log(val + Math.sqrt(val * val - 1)); },
    normalize0: function(val) {  return ((val + Math.PI*3) % (Math.PI*2)) - Math.PI; },
    normalize: function(val) {  return ((val + Math.PI*2) % (Math.PI*2)); },  
    cartesian: function(p) {
      var ϕ = p[0], θ = halfπ - p[1], r = p[2];
      return {"x": r * Math.sin(θ) * Math.cos(ϕ), "y": r * Math.sin(θ) * Math.sin(ϕ), "z": r * Math.cos(θ)};
    },
    spherical: function(p) {
      var r = Math.sqrt(p.x * p.x + p.y * p.y + p.z * p.z),
          θ = Math.atan(p.y / p.x),
          ϕ = Math.acos(p.z / r);
      return  [θ / deg2rad, ϕ / deg2rad, r];
    },
    distance: function(p1, p2) {
      return Math.acos(Math.sin(p1[1])*Math.sin(p2[1]) + Math.cos(p1[1])*Math.cos(p2[1])*Math.cos(p1[0]-p2[0]));
    }
  };