import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { useStyles } from "../../styles/styles";
import { useStarmapData } from "../../redux/slice";
import { Add, Close } from "@material-ui/icons";

export const Tab4 = () => {
  const classes = useStyles();

  const {
    d_setTitle,
    title,
    subtitle,
    d_setSubTitle,
    primaryText,
    d_setPrimaryText,
  } = useStarmapData();
  const addNewInput = () => {
    // console.log(primaryText);
    let temp = [...primaryText];
    temp.push("");
    d_setPrimaryText(temp);
  };
  const removeInput = (index) => {
    let temp = [...primaryText];
    temp.splice(index, 1);
    d_setPrimaryText(temp);
  };
  const changePrimaryText = (index, text) => {
    let temp = [...primaryText];
    temp[index] = text;
    d_setPrimaryText(temp);
  };
  return (
    <>
      <div className={classes.inputWrapper}>
        <Typography variant="subtitle2">Title</Typography>
        <TextField
          variant="outlined"
          fullWidth
          onChange={(e) => d_setTitle(e.target.value)}
          value={title}
        />
      </div>
      <div className={classes.inputWrapper}>
        <Typography variant="subtitle2">Personal Text</Typography>
        <TextField
          id="primary"
          variant="outlined"
          fullWidth
          value={subtitle}
          onChange={(e) => d_setSubTitle(e.target.value)}
        />
      </div>

      <div className={classes.inputWrapper}>
        <Typography variant="subtitle2">Secondary Text</Typography>
        {primaryText.map((text, index) => (
          <div style={{ marginTop: 10 }} key={`secondaryText-${index}`}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={(e) => changePrimaryText(index, e.target.value)}
              value={text}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => removeInput(index)}>
                      <Close fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        ))}
      </div>
      {primaryText.length < 4 && (
        <div
          style={{
            flex: 1,
            justifyContent: "flex-end",
            display: "flex",
            marginTop: 20,
          }}
        >
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => addNewInput()}
          >
            Add new line
          </Button>
        </div>
      )}
    </>
  );
};
