import React from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import { useStyles } from "../styles/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Tab1 } from "./tabs/tab1";
import { Tab2 } from "./tabs/tab2";
import { Tab3 } from "./tabs/tab3";
import { Tab4 } from "./tabs/tab4";
import { Tab5 } from "./tabs/tab5";
export default function NarrowConsole({ planetData }) {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  return (
    <div className={classes.sidearWrapper}>
      <Tabs
        value={tabValue}
        variant="fullWidth"
        textColor="primary"
        scrollButtons="auto"
        onChange={(_, newValue) => setTabValue(newValue)}
      >
        <Tab label="Style" />
        <Tab label="Color" />
        <Tab label="Location" />
        <Tab label="Text" />
        <Tab label="Frame" />
      </Tabs>
      <div className={classes.innerSideBarWrapper}>
        {tabValue === 0 && <Tab1 />}
        {tabValue === 1 && <Tab2 />}
        {tabValue === 2 && <Tab3 />}
        {tabValue === 3 && <Tab4 />}
        {tabValue === 4 && <Tab5 planetData={planetData} />}
      </div>
    </div>
  );
}
