import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { theme } from "./styles/theme";
import { ThemeProvider } from "@material-ui/core/styles";
import store from "./redux/store";
import { Provider } from "react-redux";

const reactAppData = window.rpReactPlugin || {};
const { appSelector } = reactAppData;
const appAnchorElement =
  document.querySelector(appSelector) || document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  appAnchorElement
);
