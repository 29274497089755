import { useEffect, useState, useRef, useCallback } from "react";
import "../styles/starmap.css";
import * as d3 from "d3";
import {
  euler,
  transformDeg,
  calculateRot,
  getData,
  calculateTimeOffset,
  getPlanets,
  canvasGenerator,
} from "../helpers/util";
import { useSelector, useDispatch } from "react-redux";
import tz_lookup from "tz-lookup";
import moment from "moment-timezone";
import ReactLoading from "react-loading";
import { useMemo } from "react";
import { formats, frame_styles, map_styles, sizes } from "../data/mapData";
import { useStarmapData } from "../redux/slice";
import Style0 from "./mapStyles/style0";
import Style1 from "./mapStyles/style1";
import Style2 from "./mapStyles/style2";
import Style3 from "./mapStyles/style3";
import WebFont from "webfontloader";

const RES_MULTIPLIER = 4;

function Starmap2({
  planetData,
  starData,
  constData,
  isLoading,
  dustCloudData,
}) {
  //redux states
  const {
    primaryCol,
    secondaryCol,
    primaryText,
    title,
    subtitle,
    lat,
    lng,
    date,
    showConstellation,
    showGraticule,
    showMoon,
    showMilkyway,
    size,
    format,
    style,
    inverse,
    frame,
    colorVariation,
  } = useStarmapData();

  const canvas = useRef(null);
  const artCanvas = useRef(null);
  const [width, setWidth] = useState(600);
  const [height, setHeight] = useState(800);
  const [imageData, setImageData] = useState();
  const [assetLoading, setAssetLoading] = useState(false);
  const [fontLoading, setFontLoading] = useState(true);

  const drawTheDesign = useCallback(() => {
    if (
      starData.length === 0 ||
      constData.length === 0 ||
      dustCloudData.length === 0 ||
      isLoading ||
      fontLoading
    ) {
      return;
    }
    if (map_styles[style].colorVariations.length !== 0) {
      if (imageData === undefined) {
        return;
      }
    }

    const ndate = calculateTimeOffset(date, lat, lng);
    const rot = calculateRot(ndate, lat, lng);
    let o = planetData.origin(ndate).spherical();
    let p = planetData.moon().equatorial(o);
    const moonPos = transformDeg(p.ephemeris.pos, euler["equatorial"]);
    const params = {
      canvas: canvas.current,
      width,
      height,
      res_multiplier: RES_MULTIPLIER,
      primaryCol,
      secondaryCol,
      date,
      lat,
      lng,
      starData,
      dustCloudData,
      constData,
      planetData,
      showMilkyway,
      showGraticule,
      showConstellation,
      showMoon,
      primaryText,
      title,
      rot,
      size,
      inverse,
      subtitle,
      colorVariation,
      image: imageData,
      moonData: { moonPos, age: p.ephemeris.age },
    };
    switch (style) {
      case 0:
        Style0(params, canvasGenerator);
        break;
      case 1:
        Style1(params, canvasGenerator);
        break;
      case 2:
        Style2(params, canvasGenerator);
        break;
      case 3:
        Style3(params, canvasGenerator);
        break;
      default:
        Style0(params, canvasGenerator);
    }
    if (format === 3) drawOnArtCanvas();
  }, [
    starData,
    constData,
    dustCloudData,
    isLoading,
    fontLoading,
    style,
    date,
    lat,
    lng,
    planetData,
    width,
    height,
    primaryCol,
    secondaryCol,
    showMilkyway,
    showGraticule,
    showConstellation,
    showMoon,
    primaryText,
    title,
    size,
    inverse,
    subtitle,
    colorVariation,
    imageData,
    format,
  ]);
  const drawOnArtCanvas = async () => {
    const artC = artCanvas.current;
    const targetRes = {
      width: sizes[0].width * RES_MULTIPLIER,
      height: sizes[0].height * RES_MULTIPLIER,
    };
    artC.width = 1009 / 2;
    artC.height = 1603 / 2;
    const artCtx = artC.getContext("2d");
    artCtx.fillStyle = "black";
    const image = new Image();
    image.src = `${process.env.PUBLIC_URL}/images/frames/art_display.png`;
    await new Promise((res, rej) => {
      image.onload = () => {
        res();
      };
    });
    artCtx.shadowColor = "rgba(0,0,0,0.05)";
    artCtx.shadowBlur = 20;
    artCtx.shadowOffsetX = 3;
    artCtx.drawImage(image, 0, 0, artC.width, artC.height);
    const horM = 33;
    const verM = 140;
    const asr = canvas.current.width / canvas.current.height;
    const scale = 625;
    artCtx.drawImage(canvas.current, horM, verM, asr * scale, scale);
  };
  /* images : array of images
   */
  const loadImages = async (images) => {
    setAssetLoading(true);
    const result = [];
    for (let src of images) {
      const image = new Image();
      image.src = src;
      await new Promise((res, rej) => {
        image.onload = () => {
          result.push(image);
          res();
        };
      });
    }

    setImageData(result);
    setAssetLoading(false);
    return result;
  };
  useEffect(() => {
    loadImages(map_styles[style].colorVariations[colorVariation].src);
  }, [style, colorVariation]);
  useEffect(() => {
    drawTheDesign();
  }, [drawTheDesign]);
  useEffect(() => {
    if (frame === 3) {
    }
  }, [frame]);
  useEffect(() => {
    canvas.current.oncontextmenu = (e) => e.preventDefault();
    WebFont.load({
      custom: {
        families: ["sacramento", "avenir"],
        urls: [`${process.env.PUBLIC_URL}/fonts.css`],
      },
      active: () => setFontLoading(false),
    });
  }, []);

  const getFrameStyle = () => {
    switch (frame) {
      case 0:
        return "framed-black";
      case 1:
        return "framed-white";
      case 2:
        return "framed-wood";
      default:
        return "";
    }
  };
  return (
    <>
      <div
        className={`poster ${format === 3 ? "" : "box-shadow"} ${
          format === 2 ? getFrameStyle() : ""
        }`}
      >
        <div className="frame-container">
          {(isLoading || assetLoading || fontLoading) && (
            <div className="loading">
              <ReactLoading
                type="spokes"
                color={secondaryCol}
                height={100}
                width={100}
              />
            </div>
          )}
          <canvas
            id="canvas"
            style={{ display: format !== 3 ? "block" : "none" }}
            ref={canvas}
            width={width}
            height={height}
          ></canvas>
          <canvas
            style={{ display: format === 3 ? "block" : "none" }}
            id="canvas2"
            ref={artCanvas}
            width={1009}
            height={1603}
          ></canvas>
        </div>
      </div>
    </>
  );
}

export default Starmap2;
