import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useStyles } from "./styles/styles";
import Starmap2 from "./components/starmap";
import WideConsole from "./components/wideConsole";
import NarrowConsole from "./components/narrowConsole";
import { useStarmapData } from "./redux/slice";
import * as d3 from "d3";
import { getData, getPlanets } from "./helpers/util";
// Color pallets
// #22272c
// #2a4158
// #597387
// #8c9ea3
// #958a56
function App() {
  const classes = useStyles();
  const { d_fetchWpData } = useStarmapData();
  const [constData, setConstData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [planetData, setPlanetData] = useState([]);
  const [starData, setStarData] = useState([]);
  const [dustCloudData, setDustCloudData] = useState([]);

  const _loadData = async () => {
    setIsLoading(true);

    d3.json(`${process.env.PUBLIC_URL}/data/stars.6.json`).then(function (
      data,
      err
    ) {
      if (err) {
        console.log(err);
      }
      setStarData(data);
    },
      []);

    d3.json(`${process.env.PUBLIC_URL}/data/constellations.lines.json`).then(
      function (data, err) {
        let transformed_data = getData(data, "equatorial");
        setConstData(transformed_data);
      }
    );
    d3.json(`${process.env.PUBLIC_URL}/data/planets.json`).then(function (
      data,
      err
    ) {
      let transformed_data = getPlanets(data);
      setPlanetData(transformed_data);
    });
    d3.json(`${process.env.PUBLIC_URL}/data/milkyway.json`).then(function (
      data,
      err
    ) {
      let transformed_data = getData(data, "equatorial");
      setDustCloudData(transformed_data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    _loadData();
    d_fetchWpData();
  }, []);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={12} md={9} lg={9}>
        <div className="mainContainer">
          <Starmap2
            planetData={planetData}
            constData={constData}
            starData={starData}
            dustCloudData={dustCloudData}
            isLoading={isLoading}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} className={classes.grid}>
        <div className="wide-console">
          <WideConsole planetData={planetData} />
        </div>
        <div className="narrow-console">
          <NarrowConsole planetData={planetData} />
        </div>
      </Grid>
    </Grid>
  );
}

export default App;
