import { Typography } from "@material-ui/core";
import { map_styles } from "../../data/mapData";
import { useStyles } from "../../styles/styles";
import { useStarmapData } from "../../redux/slice";

export const Tab2 = () => {
  const classes = useStyles();

  const { style, d_setColorVariation, colorVariation } = useStarmapData();

  return (
    <>
      <div className={classes.stylesWrapper}>
        {map_styles[style].colorVariations.map((tile, index) => (
          <div
            className={classes.styleItem}
            onClick={() => d_setColorVariation(index)}
            key={`${tile.label}-colorvar`}
          >
            <div className={classes.styleImageWrap}>
              <img
                src={tile.thumbnail}
                alt={tile.label}
                className={classes.styleImage}
                style={
                  index === colorVariation
                    ? { borderColor: "rgba(47,57,75,.8)" }
                    : { borderColor: "rgba(47,57,75,.2)" }
                }
              />
              <Typography className={classes.styleText}>
                {tile.label}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
