import { deg2rad, halfπ, τ } from "./mathConstants";
import { Kepler } from "./kepler";

import tz_lookup from "tz-lookup";
import moment from "moment-timezone";
import { encode, decode } from "js-base64";

const eulerAngles = {
  equatorial: [0.0, 0.0, 0.0],
  ecliptic: [0.0, 0.0, 23.4393],
  galactic: [93.5949, 28.9362, -58.5988],
  supergalactic: [137.31, 59.5283, 57.7303],
  //  "mars": [97.5,23.5,29]
};
export const handleCheckout = async (
  data,
  wp_id,
  redirect,
  is_test = false
) => {
  if (is_test) {
    testCheckout(data);
    return;
  }
  const wordpress_data = window.rpReactPlugin || {
    ajaxurl: "https://wildskyclub.com/wp-admin/admin-ajax.php",
  };
  if (!wordpress_data.ajaxurl) {
    console.log("no ajax url found");
    return;
  }
  const formData = new FormData();
  formData.append("action", "add_starmap");
  formData.append("starmap_data", encode(JSON.stringify(data)));
  formData.append("starmap_variation_id", wp_id);
  // formData.append("nonce", wordpress_data.nonce);
  const res = await fetch(wordpress_data.ajaxurl, {
    method: "POST",
    body: formData,
    redirect: "follow",
    credentials: "same-origin",
  });
  if (res.redirected) {
    if (redirect) {
      window.location.href = res.url;
    } else {
      window.location.reload();
    }
  }
};

const testCheckout = async (data) => {
  const res = await fetch("http://localhost:4000/hooks/preview", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data: btoa(JSON.stringify(data)) }),
    method: "POST",
  });
};
function has(o, key) {
  return o !== null && hasOwnProperty.call(o, key);
}
export function calculateRot(date, lat, lng) {
  let ndate = calculateTimeOffset(date, lat, lng);
  let zenith = getPoint(
    horizontalInverse(ndate, [90, 0], [lat, lng]),
    "equatorial"
  );
  zenith[2] = 0;
  return getAngles(zenith);
}
export function calculateTimeOffset(date, lat, lng) {
  let localZone = -date.getTimezoneOffset();
  let lookup = localZone;
  try {
    lookup = tz_lookup(lat, lng);
  } catch (e) {
    return new Date();
  }
  let timeZone = moment.tz(lookup).utcOffset();
  return new Date(date.valueOf() - (timeZone - localZone) * 60000);
}
export function getAngles(coords) {
  if (coords === null || coords.length <= 0) return [0, 0, 0];
  var rot = eulerAngles.equatorial;
  if (!coords[2]) coords[2] = 0;
  return [rot[0] - coords[0], rot[1] - coords[1], rot[2] + coords[2]];
}
export function transformDeg(c, euler) {
  var res = transform(
    c.map(function (d) {
      return d * deg2rad;
    }),
    euler
  );
  return res.map(function (d) {
    return d / deg2rad;
  });
}
export function getPoint(coords, trans) {
  return transformDeg(coords, euler[trans]);
}
export function canvasGenerator() {
  return document.createElement("canvas");
}
export function getPlanets(d) {
  var res = {};
  for (var key in d) {
    if (!has(d, key)) continue;
    var dat = Kepler().id(key);
    if (has(d[key], "parent")) dat.parentBody(d[key].parent);
    dat.elements(d[key].elements[0]).params(d[key]);
    if (key === "ter") res.origin = dat;
    if (key === "lun") res.moon = dat;
  }
  return res;
}

export function getData(d, trans) {
  if (trans === "equatorial") return d;

  var leo = euler[trans],
    f = d.features;

  for (var i = 0; i < f.length; i++)
    f[i].geometry.coordinates = translate(f[i], leo);

  return d;
}

function translate(d, leo) {
  var res = [];
  // eslint-disable-next-line default-case
  switch (d.geometry.type) {
    case "Point":
      res = transformDeg(d.geometry.coordinates, leo);
      break;
    case "LineString":
      res.push(transLine(d.geometry.coordinates, leo));
      break;
    case "MultiLineString":
      res = transMultiLine(d.geometry.coordinates, leo);
      break;
    case "Polygon":
      res.push(transLine(d.geometry.coordinates[0], leo));
      break;
    case "MultiPolygon":
      res.push(transMultiLine(d.geometry.coordinates[0], leo));
      break;
  }

  return res;
}
function transLine(c, leo) {
  var line = [];

  for (var i = 0; i < c.length; i++) line.push(transformDeg(c[i], leo));

  return line;
}

function transMultiLine(c, leo) {
  var lines = [];

  for (var i = 0; i < c.length; i++) lines.push(transLine(c[i], leo));

  return lines;
}
//Transform equatorial into any coordinates, degrees

//Transform equatorial into any coordinates, radians
function transform(c, euler) {
  var x,
    y,
    z,
    β,
    γ,
    λ,
    φ,
    dψ,
    ψ,
    θ,
    ε = 1.0e-5;

  if (!euler) return c;

  λ = c[0]; // celestial longitude 0..2pi
  if (λ < 0) λ += τ;
  φ = c[1]; // celestial latitude  -pi/2..pi/2

  λ -= euler[0]; // celestial longitude - celestial coordinates of the native pole
  β = euler[1]; // inclination between the poles (colatitude)
  γ = euler[2]; // native coordinates of the celestial pole

  x = Math.sin(φ) * Math.sin(β) - Math.cos(φ) * Math.cos(β) * Math.cos(λ);
  if (Math.abs(x) < ε) {
    x = -Math.cos(φ + β) + Math.cos(φ) * Math.cos(β) * (1 - Math.cos(λ));
  }
  y = -Math.cos(φ) * Math.sin(λ);

  if (x !== 0 || y !== 0) {
    dψ = Math.atan2(y, x);
  } else {
    dψ = λ - Math.PI;
  }
  ψ = γ + dψ;
  if (ψ > Math.PI) ψ -= τ;

  if (λ % Math.PI === 0) {
    θ = φ + Math.cos(λ) * β;
    if (θ > halfπ) θ = Math.PI - θ;
    if (θ < -halfπ) θ = -Math.PI - θ;
  } else {
    z = Math.sin(φ) * Math.cos(β) + Math.cos(φ) * Math.sin(β) * Math.cos(λ);
    if (Math.abs(z) > 0.99) {
      θ = Math.abs(Math.acos(Math.sqrt(x * x + y * y)));
      if (z < 0) θ *= -1;
    } else {
      θ = Math.asin(z);
    }
  }

  return [ψ, θ];
}

export var euler = {
  ecliptic: [-90.0, 23.4393, 90.0],
  "inverse ecliptic": [90.0, 23.4393, -90.0],
  galactic: [-167.1405, 62.8717, 122.9319],
  "inverse galactic": [122.9319, 62.8717, -167.1405],
  supergalactic: [283.7542, 74.2911, 26.4504],
  "inverse supergalactic": [26.4504, 74.2911, 283.7542],
  init: function () {
    for (var key in this) {
      if (this[key].constructor === Array) {
        this[key] = this[key].map(function (val) {
          return val * deg2rad;
        });
      }
    }
  },
  add: function (name, ang) {
    if (!ang || !name || ang.length !== 3 || this.hasOwnProperty(name)) return;
    this[name] = ang.map(function (val) {
      return val * deg2rad;
    });
    return this[name];
  },
};

export const horizontalInverse = function (dt, hor, loc) {
  var alt = hor[0] * deg2rad;
  var az = hor[1] * deg2rad;
  var lat = loc[0] * deg2rad;

  var dec = Math.asin(
    Math.sin(alt) * Math.sin(lat) + Math.cos(alt) * Math.cos(lat) * Math.cos(az)
  );
  var ha = (
    (Math.sin(alt) - Math.sin(dec) * Math.sin(lat)) /
    (Math.cos(dec) * Math.cos(lat))
  ).toFixed(6);

  ha = Math.acos(ha);
  ha = ha / deg2rad;
  var ra = getMST(dt, loc[1]) - ha;
  //if (ra < 0) ra = ra + 360;
  return [ra, dec / deg2rad, 0];
};

function getMST(dt, lng) {
  var yr = dt.getUTCFullYear();
  var mo = dt.getUTCMonth() + 1;
  var dy = dt.getUTCDate();
  var h = dt.getUTCHours();
  var m = dt.getUTCMinutes();
  var s = dt.getUTCSeconds();

  if (mo === 1 || mo === 2) {
    yr = yr - 1;
    mo = mo + 12;
  }

  var a = Math.floor(yr / 100);
  var b = 2 - a + Math.floor(a / 4);
  var c = Math.floor(365.25 * yr);
  var d = Math.floor(30.6001 * (mo + 1));

  // days since J2000.0
  var jd = b + c + d - 730550.5 + dy + (h + m / 60.0 + s / 3600.0) / 24.0;

  // julian centuries since J2000.0
  var jt = jd / 36525.0;

  // the mean sidereal time in degrees
  var mst =
    280.46061837 +
    360.98564736629 * jd +
    0.000387933 * jt * jt -
    (jt * jt * jt) / 38710000 +
    lng;

  // in degrees modulo 360.0
  if (mst > 0.0) while (mst > 360.0) mst = mst - 360.0;
  else while (mst < 0.0) mst = mst + 360.0;

  return mst;
}
