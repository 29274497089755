import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
export const CSwitch = withStyles((theme) => ({
  root: {
    width: 54,
    height: 26,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    color: "rgba(46, 59, 73, 0.5)",
    "&$checked": {
      transform: "translateX(28px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#00ADB5",
        opacity: 1,
        borderColor: theme.palette.primary.main,
      },
    },
    // "&$focusVisible $thumb": {
    //   color: "#00ADB5",
    //   border: "6px solid #fff",
    // },
  },
  thumb: {
    width: 24,
    height: 24,
    boxShadow: "none",
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid rgba(46, 59, 73, 0.5)`,
    height: 24,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    // transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
}))(Switch);

export const reactSelectStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      backgroundColor: "#fff",
      borderColor: "hsla(212, 23%, 23%, 0.3)",
      height: 54,
    };
  },
};
